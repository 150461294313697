import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

function TravelAdvisoryCoronavirus(props) {
  return (
    <>
      <SEO title="Travel Advisory - Coronavirus | Discover New Zealand - awesomeNZ" />
      <Layout
        layoutType="typography TourHolderPage"
        slideshow={
          <ul class="slideshow-slides">
            <li class="slideshow-slide first">
              <img
                src={require("../images/sliders/coach.jpg")}
                alt=""
                class="first last image"
              />
            </li>
          </ul>
        }
        hasSliderHandles={false}
        hasSliderBlurSmall={true}
        content={
          <>
            <div id="TourHolderPage" class="clearfix">
              <div class="title-div">
                <h1>Travel Advisory - Coronavirus</h1>
                <h3>Important Information</h3>
              </div>
              <div class="destination-content">
                {/* ... */}
                <p>
                  The World Health Organisation has declared the Coronavirus
                  epidemic a public health emergency of international concern.
                  Coronavirus is a highly infectious virus that spreads easily
                  from person to person via close personal contact, breathing,
                  coughing and sneezing, and affects both children and adults.
                </p>
                <p>
                  If you think you, or one of your group may have been exposed
                  to Coronavirus <strong>and</strong> have arrived from or
                  transited through mainland China within the last 14 days{" "}
                  <strong>and</strong> are exhibiting any of these symptoms:
                </p>
                <ul>
                  <li>fever</li>
                  <li>cough</li>
                  <li>shortness of breath</li>
                </ul>
                <p>
                  <strong>
                    Please do not travel with us. We will happily transfer your
                    booking to a later date.
                  </strong>
                </p>
                <p>
                  We advise you NOT to go to a general practitioner. Instead you
                  should call your health care provider, or you can call NZ
                  Healthline on <a href="tel:0800611116">0800 611 116</a> for
                  free health advice, 24 hours a day.
                </p>
                {/* ... */}
              </div>
            </div>
          </>
        }
      />
    </>
  )
}

export default TravelAdvisoryCoronavirus
